






































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { useMainStore } from '@/store';
import { UserSelfForm } from './api';
import DefaultIcon from '@/assets/logo.png';

const StatusMap = new Map([
  ['pending', '待处理'],
  ['processing', '处理中'],
  ['completed', '完成'],
]);
const StatusStepMap = new Map([
  ['pending', 0],
  ['processing', 1],
  ['completed', 2],
]);

const StatusColorMap = new Map([
  ['pending', '#0091FF'],
  ['processing', '#6DD400'],
  ['completed', '#CACDD6'],
]);

export default defineComponent({
  props: {
    forms: {
      type: Array as PropType<UserSelfForm[]>,
      default: () => [],
    },
  },
  setup() {
    const { state } = useMainStore();
    const isShowDetail = ref(false);
    const current = ref<UserSelfForm>();
    return {
      isShowDetail,
      current,
      oss: computed(() => state.oss),
      formatStatusLabel: (form: UserSelfForm) => StatusMap.get(form.status) || '',
      formatStatusColor: (form: UserSelfForm) => StatusColorMap.get(form.status) || 'black',
      formatStatusStep: (form: UserSelfForm) => StatusStepMap.get(form.status) || 0,
      getIcon: (form: UserSelfForm) => {
        if (form.form_define.id) {
          const module = state.modules.find(m => m.form_id === form.form_define.id.toString());
          if (module) {
            return module.icon ? state.oss + module.icon : DefaultIcon;
          }
        }
        return DefaultIcon;
      },
    };
  },
});
