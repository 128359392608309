














import { computed, defineComponent, PropType } from '@vue/composition-api';
import { useMainStore } from '@/store';
import { Collect } from './api';
import { usePatentStore } from '../Patent/store';

export default defineComponent({
  props: {
    collects: {
      type: Array as PropType<Collect[]>,
      default: () => [],
    },
  },
  setup(_, { root }) {
    const { state } = useMainStore();
    const { setCurrent } = usePatentStore();
    return {
      toDetail(item: Collect) {
        setCurrent({ ...item.content, collectId: item.id });
        root.$router.push({ name: 'PatentDetail' });
      },
      oss: computed(() => state.oss),
    };
  },
});
