
























































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useMainStore } from '@/store';
import { Collect, updateUserInfo } from './api';
import { usePatentStore } from '../Patent/store';
import AvatarDefaultImage from '@/assets/avatar.png';
import Card from '@/components/Card.vue';
import { Message } from 'element-ui';

export default defineComponent({
  components: { Card },
  props: {
    statistics: { type: Array, default: () => [] },
  },
  setup(_, { root }) {
    const { state, updateUser } = useMainStore();
    const { setCurrent } = usePatentStore();
    const isShowEdit = ref(false);
    const userFormValue = ref<{ nickname: string; company: string }>();

    watch(isShowEdit, show => {
      if (show && state.user) {
        userFormValue.value = {
          nickname: state.user.nickname,
          company: state.user.company,
        };
      }
    });

    return {
      state,
      userFormValue,
      isShowEdit,
      avatar: computed(() =>
        state.user && state.user.avatar ? state.user.avatar : AvatarDefaultImage,
      ),
      submit: async () => {
        if (
          isShowEdit.value &&
          userFormValue.value &&
          userFormValue.value.nickname &&
          userFormValue.value.company
        ) {
          await updateUserInfo(userFormValue.value);
          await updateUser();
          Message.success('修改成功');
          isShowEdit.value = false;
        }
      },
    };
  },
});
