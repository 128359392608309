



































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useMainStore } from '@/store';
import { TabPane } from 'element-ui';
import Card from '@/components/Card.vue';
import { useApi } from '@/util/useApi';
import { getMyCollects, getSelfForms } from './api';
import dayjs from 'dayjs';
import Progress from './Progress.vue';
import Collect from './Collect.vue';
import UserInfo from './UserInfo.vue';

export default defineComponent({
  components: { Card, Progress, Collect, UserInfo },
  setup(_, { root }) {
    const { state } = useMainStore();

    const { result: selfForms } = useApi(() => getSelfForms());
    const { result: collects } = useApi(() => getMyCollects());

    return {
      selfForms: computed(() =>
        (selfForms.value || []).sort(
          (a, b) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix(),
        ),
      ),
      collects,
      statistics: computed(() => [
        {
          num: selfForms.value ? selfForms.value.length : 0,
          unit: '条',
          label: '服务进度',
        },
        {
          num: collects.value ? collects.value.length : 0,
          unit: '条',
          label: '收藏',
        },
        {
          num: 450,
          unit: '条',
          label: '活动',
        },
      ]),
      tabs: [
        { name: '服务进度', icon: 'el-icon-s-order' },
        { name: '我的收藏', icon: 'el-icon-star-off' },
        { name: '活动历程', icon: 'el-icon-date' },
      ],
      state,
      current: root.$route.hash.replace('#', '') || '服务进度',
      onTabClick: (tab: TabPane) => {
        location.hash = tab.name;
      },
    };
  },
});
