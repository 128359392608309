import { request } from '@/util/request';
import { Collect, UserSelfForm } from './model';

export * from './model';

export function getSelfForms() {
  return request<UserSelfForm[]>('/strapi/forms', {
    // params: {
    //   _start: (page - 1) * size,
    //   _limit: size,
    // },
  });
}

export function getMyCollects() {
  return request<Collect[]>(`/strapi/self/collects`);
}

export function updateUserInfo(data: { company: string; nickname: string }) {
  return request(`/strapi/user/me`, {
    method: 'PUT',
    data,
  });
}
